<template>
<div>
    <div class="">
      <div class="row">
        <div class="col-12">
            <h2 class="my-3" style="font-size:1.2em; display:inline-block">Market Place</h2>
          <button style="float:right;" data-toggle="modal" data-target="#exampleModal" class="btn btn-danger my-2">
             <i class="fas fa-pen-alt"></i> add new market
          </button>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
           <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form >
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" v-model="name" placeholder="name" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="role">Role</label>
                        <input type="text" v-model="role" placeholder="role" class="form-control">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button @click="addNewMarket" type="button" class="btn btn-primary">Submit</button>
            </div>
            </div>
        </div>
        </div>
        </div>
      </div>
      <div class="card shadow-sm mb-5">
          <div class="card-header">
                  <h3 class="my-2 text-secondary" style="font-size:1em;">Market Places</h3>
              </div>
        <div class="card-body">
        <div class="table-responsive">
            <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            >
            <thead>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Market Name</th>
                </tr>
            </thead>
            <tbody >
                <tr v-for="market in marketplace" :key="market.index">
                <td>{{market.id}}</td>
                <td>{{market.created_at | moment}}</td>
                <td>{{market.name}}</td>
                </tr>
            </tbody>
            </table>
            <!-- Modal -->
        </div>
        </div>
    </div>
  </div>

  
</div>
</template>     
<script>

import { mapGetters } from 'vuex'
import moment from 'moment'
const $ = require('jquery')
// import Table from '../admin/Table'

export default {
    name:'global-admin',
   data() {
       return {
           error:"",
           marketplace:"",
           name:'',
           role:''
       }
   },
   methods:{
       getMarket(){
           this.$store.dispatch('fetchGlobalAdminMarket')
           .then(response=>{
               this.marketplace = response.data
               setTimeout(function(){$('#dataTable').DataTable();}, 100);
           })
           .catch(err=>{
               if(err?.response?.data === undefined){
                   this.error = 'NetworkError'
               }
           })
       },
       addNewMarket(){
           this.$store.dispatch('addGlobalAdminMarket',{
               name:this.name,
               role:this.role
           }).then(response=>{
               this.$toasted.success('market has been added successfully')
           }).catch(err=>{
               console.log(err)
           })
       }
   },
   mounted() {
       this.getMarket()
       this.addNewMarket()
   },
    
}
</script>
<style scoped>
.nav-link{
    color: #ba2428;
}
a#pills-profile-tab.nav-link.active,a#pills-home-tab.nav-link.active{
    background-color:#ba2428;
    color:#fff;
}
.action{
    width:110px;

}
button, table tr td{
    font-size: .85em;
}
</style>